import fetch from 'cross-fetch';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getSessionToken, removeSessionToken } from './session';

const SERVER_URL = process.env.GATSBY_SERVER_URL;

const httpLink = createHttpLink({
  uri: `${SERVER_URL}/api`,
  fetch,
});

const resetToken = onError(({ networkError }) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    removeSessionToken();
    redirectToAuth();
  }
});

export const redirectToAuth = () => {
  const webPath = encodeURIComponent(
    (location.pathname + location.search).substr(1)
  );
  window.location.href = `${SERVER_URL || ''}/login?web_path=${webPath}`;
};

const withToken = setContext((_, { headers }) => {
  const token = getSessionToken();

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    return headers;
  }
});

const authLink = withToken.concat(resetToken);

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
