const SESSION_TOKEN = 'SESSION_TOKEN';

export const getSessionToken = () => {
  return localStorage.getItem(SESSION_TOKEN);
};
export const removeSessionToken = () => {
  return localStorage.removeItem(SESSION_TOKEN);
};

export const persistSessionToken = (token: string) => {
  return localStorage.setItem(SESSION_TOKEN, token);
};
