import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { redirectToAuth } from '../utils/apollo';
import {
  getSessionToken,
  persistSessionToken,
  removeSessionToken,
} from '../utils/session';
interface CurrentUser {
  name: string;
  c2Username: string;
  trackMemberships: TrackMembership[];
}

interface Track {
  id: string;
}

interface TrackMembership {
  track: Track;
  active: boolean;
  trial: boolean;
  status: string;
  recurring?: string;
  description?: string;
}

interface CurrentUserContextType {
  currentUser: CurrentUser;
  redirectToAuth: () => void;
  logout: () => void;
  savePersistedToken: (token: string) => void;
}

const CurrentUserContext = createContext<CurrentUserContextType>({
  // @ts-ignore
  currentUser: {},
});

const GET_USER = gql`
  query GetUser {
    currentUser {
      name
      c2Username
      trackMemberships {
        active
        trial
        status
        description
        recurring
        track {
          id
        }
      }
    }
  }
`;

const SESSION_CREATION = gql`
  mutation CreateSession($token: String!) {
    session: createSession(token: $token, device: { deviceName: "web" }) {
      id
    }
  }
`;

export function CurrentUserProvider(props: any) {
  const client = useApolloClient();
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [createSession] = useMutation(SESSION_CREATION, {});

  const [lazyloadCurrentUser] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setCurrentUser(data.currentUser);
      }
    },
  });

  const savePersistedToken = useCallback((token) => {
    if (token) {
      createSession({ variables: { token } }).then(({ data }) => {
        if (data) {
          persistSessionToken(data.session.id);
          lazyloadCurrentUser();
        }
      });
    }
  }, []);

  useEffect(() => {
    const sessionToken = getSessionToken();
    if (sessionToken) {
      lazyloadCurrentUser();
    }
  }, []);

  const logout = useCallback(() => {
    removeSessionToken();
    client.clearStore();
    window.location.reload();
  }, [client]);

  const value = {
    redirectToAuth,
    currentUser,
    savePersistedToken,
    logout,
  };

  return (
    <CurrentUserContext.Provider
      value={value}
      {...props}
      children={props.children}
    />
  );
}

const useCurrentUser = () => {
  return useContext(CurrentUserContext) as CurrentUserContextType;
};

export default useCurrentUser;
