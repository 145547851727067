import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { client } from './src/utils/apollo';
import { CurrentUserProvider } from './src/hooks/useCurrentUser';

export const shouldUpdateScroll = () => {
  return true;
};

export const wrapRootElement = (props) => {
  const { element } = props;
  return (
    <ApolloProvider client={client}>
      <CurrentUserProvider>{element}</CurrentUserProvider>
    </ApolloProvider>
  );
};
