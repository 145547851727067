// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-discover-page-tsx": () => import("./../../../src/components/DiscoverPage.tsx" /* webpackChunkName: "component---src-components-discover-page-tsx" */),
  "component---src-components-track-page-tsx": () => import("./../../../src/components/TrackPage.tsx" /* webpackChunkName: "component---src-components-track-page-tsx" */),
  "component---src-components-track-price-page-tsx": () => import("./../../../src/components/TrackPricePage.tsx" /* webpackChunkName: "component---src-components-track-price-page-tsx" */),
  "component---src-components-track-token-page-tsx": () => import("./../../../src/components/TrackTokenPage.tsx" /* webpackChunkName: "component---src-components-track-token-page-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-markdown-template-js": () => import("./../../../src/templates/markdown-template.js" /* webpackChunkName: "component---src-templates-markdown-template-js" */)
}

